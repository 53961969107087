.signature {
    /* padding: 0px 15rem; */
    max-width: 15rem;
}
.signature svg {
    --delay: 0.2s;
    --filling: var(--color-text-primary);
    --secondColor: theme("colors.yellow.300");
    --walid-cubic: ease-in-out;
    --walid-duration: 1.4s;
    fill: #0000;
    stroke-width: 2px;
    stroke: var(--filling);
    transition: all 0.4s ease;
    animation: svgfillingonce 2s ease-in-out forwards calc(((18 * var(--delay)) * 1) + 1s);
}
.svg-mohsen {
    transform: translate(7px, 11px);
}
.signature .svg-a path:nth-child(1) {
    stroke-dasharray: 54.3px;
    stroke-dashoffset: 54.3px;
    animation: svgfillonce var(--walid-duration) var(--walid-cubic) forwards calc(1 * var(--delay));
}
.signature .svg-a path:nth-child(2) {
    stroke-dasharray: 81.3px;
    stroke-dashoffset: 81.3px;
    animation: svgfillonce var(--walid-duration) var(--walid-cubic) forwards calc(2 * var(--delay));
}
.signature .svg-slash path {
    stroke-dasharray: 146.7px;
    stroke-dashoffset: 146.7px;
    animation: svgfillonce var(--walid-duration) var(--walid-cubic) forwards calc(3 * var(--delay));
}

.signature .svg-walid path:nth-child(1) {
    animation: svgfillonce var(--walid-duration) var(--walid-cubic) forwards calc(4 * var(--delay));
    stroke-dasharray: 64.3px;
    stroke-dashoffset: 64.3px;
}

.signature .svg-walid path:nth-child(2) {
    animation: svgfillonce var(--walid-duration) var(--walid-cubic) forwards calc(5 * var(--delay));
    stroke-dasharray: 47.3px;
    stroke-dashoffset: 47.3px;
}
.signature .svg-walid path:nth-child(3) {
    animation: svgfillonce var(--walid-duration) var(--walid-cubic) forwards calc(6 * var(--delay));
    stroke-dasharray: 73.3px;
    stroke-dashoffset: 73.3px;
}
.signature .svg-walid path:nth-child(4) {
    animation: svgfillonce var(--walid-duration) var(--walid-cubic) forwards calc(7 * var(--delay)),
        svgfillingonceColored 2s ease-in-out forwards calc(((18 * var(--delay)) * 0.9) + 1s);
    stroke: var(--secondColor);
    /* transform: translateY(4px); */
    stroke-dasharray: 40.5px;
    stroke-dashoffset: 40.5px;
}
.signature .svg-walid path:nth-child(5) {
    animation: svgfillonce var(--walid-duration) var(--walid-cubic) forwards calc(8 * var(--delay));
    stroke-dasharray: 122.8px;
    stroke-dashoffset: 122.8px;
}
.signature .svg-walid path:nth-child(6) {
    animation: svgfillonce var(--walid-duration) var(--walid-cubic) forwards calc(9 * var(--delay)),
        svgfillingonceColored 2s ease-in-out forwards calc(((18 * var(--delay)) * 0.9) + 1s);
    stroke: var(--secondColor);
    stroke-dasharray: 40.5px;
    stroke-dashoffset: 40.5px;
}
.signature .svg-walid path:nth-child(7) {
    animation: svgfillonce var(--walid-duration) var(--walid-cubic) forwards calc(10 * var(--delay));
    stroke-dasharray: 122px;
    stroke-dashoffset: 122px;
}

.signature .svg-mohsen path:nth-child(1) {
    animation: svgfillonce var(--walid-duration) var(--walid-cubic) forwards calc(11 * var(--delay)),
        svgfillingonceColored 2s ease-in-out forwards calc(((18 * var(--delay)) * 0.9) + 1s);
    stroke: var(--secondColor);
    stroke-dasharray: 64.9px;
    stroke-dashoffset: 64.9px;
}
.signature .svg-mohsen path:nth-child(2) {
    animation: svgfillonce var(--walid-duration) var(--walid-cubic) forwards calc(12 * var(--delay));
    stroke-dasharray: 176.8px;
    stroke-dashoffset: 176.8px;
}
.signature .svg-mohsen path:nth-child(3) {
    animation: svgfillonce var(--walid-duration) var(--walid-cubic) forwards calc(13 * var(--delay)),
        svgfillingonceColored 2s ease-in-out forwards calc(((18 * var(--delay)) * 0.9) + 1s);
    stroke: var(--secondColor);
    stroke-dasharray: 40.5px;
    stroke-dashoffset: 40.5px;
}
.signature .svg-mohsen path:nth-child(4) {
    animation: svgfillonce var(--walid-duration) var(--walid-cubic) forwards calc(14 * var(--delay));
    stroke-dasharray: 103px;
    stroke-dashoffset: 103px;
}
.signature .svg-mohsen path:nth-child(5) {
    animation: svgfillonce var(--walid-duration) var(--walid-cubic) forwards calc(15 * var(--delay)),
        svgfillingonceColored 2s ease-in-out forwards calc(((18 * var(--delay)) * 0.9) + 1s);
    stroke: var(--secondColor);
    stroke-dasharray: 65px;
    stroke-dashoffset: 65px;
}
.signature .svg-mohsen path:nth-child(6) {
    animation: svgfillonce var(--walid-duration) var(--walid-cubic) forwards calc(16 * var(--delay));
    stroke-dasharray: 124.6px;
    stroke-dashoffset: 124.6px;
}
.signature .svg-mohsen path:nth-child(7) {
    animation: svgfillonce var(--walid-duration) var(--walid-cubic) forwards calc(17 * var(--delay)),
        svgfillingonceColored 2s ease-in-out forwards calc(((18 * var(--delay)) * 0.9) + 1s);
    stroke: var(--secondColor);
    /* animation-delay: ); */
    stroke-dasharray: 67.5px;
    stroke-dashoffset: 67.5px;
}
.signature .svg-mohsen path:nth-child(8) {
    animation: svgfillonce var(--walid-duration) var(--walid-cubic) forwards calc(18 * var(--delay));
    stroke-dasharray: 101.8px;
    stroke-dashoffset: 101.8px;
}

@keyframes svgfillonce {
    to {
        stroke-dashoffset: 0;
    }
}
@keyframes svgfillingonce {
    from {
        fill: transparent;
        transition: all 0.4s ease;
    }
    to {
        fill: var(--filling);
        transition: all 0.4s ease;
    }
}
@keyframes svgfillingonceColored {
    from {
        fill: transparent;
        transition: all 0.4s ease;
    }
    to {
        fill: var(--secondColor);
        transition: all 0.4s ease;
    }
}
