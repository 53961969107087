@import url("https://fonts.googleapis.com/css2?family=Lemonada:wght@300;400;500;600;700&display=swap");
html {
    scroll-behavior: smooth;
}
.bg-gradient-bg-2 {
    background: linear-gradient(
        to right,
        #0000,
        /* theme("colors.yellow.400"), */ theme("colors.yellow.400"),
        /* theme("colors.yellow.400"), */ theme("colors.yellow.400")
    );
}

.table-style td,
.table-style th {
    color: var(--color-text-primary);
    border: 1px solid var(--color-text-secondary);
    transition: all var(--transition-smooth);
    padding: 8px;
}
.table-style th {
    background-color: var(--color-secondary-container);
}

.wavy-divider {
    position: relative;
    width: 100%;
    height: 50px;
    /* height: 30px; */
    /* background-color: #030; */
    overflow: hidden;
}
.wavy-divider::before {
    content: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa";
    position: absolute;
    left: -1px;
    top: -2.4rem;
    /* top: -1rem; */
    color: transparent;
    font-size: 3rem;
    /* font-size: 1.5rem; */
    text-decoration-style: wavy;
    text-decoration-line: underline;
    text-decoration-color: theme("colors.teal.500");
    animation: waving 1s linear infinite;
    /* animation: waving 0.5s linear infinite; */
}
@keyframes waving {
    0% {
        transform: translateX(-1px);
    }
    100% {
        transform: translateX(-22px);
        /* transform: translateX(-12px); */
    }
}

.years__container {
    --box-border: #0001;
    --back-color: #0001;
    --shadow-color: #0003;
    --blur: blur(5px);
    /* background-color: var(--back-color); */
    overflow: hidden;
    box-shadow: 20px 20px 50px var(--shadow-color);
    border-radius: var(--border-radius-medium);
    border-top: 1px solid var(--box-border);
    border-left: 1px solid var(--box-border);
    -webkit-backdrop-filter: saturate(180%) var(--blur);
    backdrop-filter: saturate(180%) var(--blur);
    padding: 6rem 0rem 1rem;
}
.darkmode .years__container {
    --box-border: #fff3;
    --back-color: #fff1;
    --shadow-color: #000a;
}
.bg-gradient-bg {
    background: linear-gradient(
        to top,
        #0000,
        /* theme("colors.yellow.300"), */ theme("colors.yellow.300")
    );
}
.darkmode .bg-gradient-bg {
    background: linear-gradient(
        to top,
        #0000,
        /* theme("colors.yellow.200"), */ theme("colors.yellow.200")
    );
}

/* bg-yellow-300 dark:bg-yellow-200 */
/* 
/* 
#customers tr:nth-child(even) {
    background-color: #f2f2f2;
}

#customers tr:hover {
    background-color: #ddd;
}

#customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04aa6d;
    color: white;
} */
/* .loading span {
    position: relative;
    width: 10px;
    height: 10px;
    background-color: currentColor;
    border-radius: 50%;
    margin: 1px;

    animation: jump 0.5s linear infinite;
}
.loading .a {
    animation: a 1s linear infinite;
}
.loading .b {
    animation: b 2s linear infinite;
}
.loading .c {
    animation: a 1s linear infinite;
}
.loading .d {
    animation: b 2s linear infinite;
}
.loading .e {
    animation: a 1s linear infinite;
}

@keyframes a {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes b {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0px);
    }
} */
.years-section {
    /* background-color: var(--color-yellow-light-2); */
    width: 100%;
    --head-height: 20rem;
    --head-color: var(--color-dark-2);
}

.years-section img {
    max-width: 100%;
    height: auto;
}
.years__head {
    overflow: hidden;
    position: relative;
    height: var(--head-height);
}
.years__title {
    color: var(--head-color);
    font-weight: var(--font-w-medium);
    font-size: var(--font-size-h1);
}
.courses .years__title {
    font-size: var(--font-size-big);
}

.years__circle {
    animation: toSpin 40s linear infinite;
    position: absolute;
    height: calc(var(--head-height) * 2);
    width: calc(var(--head-height) * 2);
    top: 0px;
    border-radius: 100%;
    border: 3px dashed var(--head-color);
    z-index: 1;
    transition: all 10s ease;
}

.years__circle:hover {
    animation-play-state: paused;
}
.years__body {
    position: relative;
    z-index: 10;
    width: 100%;
    background-color: var(--color-outer-container);
}

@media (max-width: 1200px) {
    .main__svg-wrapper {
        transform: translateX(0px);
    }
    .main__title {
        margin: 3rem 0rem 1rem;
        font-size: 4rem;
    }
    .main__title__first.writing::after {
        content: "";
        height: 4rem;
        position: absolute;
        top: 2rem;
        left: 0px;
        border: 3px solid var(--color-text-secondary);
    }
    .years-section {
        --head-height: 17rem;
    }
}
@media (max-width: 992px) {
    .years__title {
        font-size: var(--font-size-h2);
    }
    .years-section {
        --head-height: 14rem;
    }
}
@media (max-width: 768px) {
    .main__title {
        margin: 3rem 0rem 1rem;
        font-size: 3.7rem;
    }
    .courses .years__card {
        flex-direction: column-reverse;
    }
    .courses .years__card__text {
        text-align: center;
    }
    .main__image__wrapper {
        max-width: 75%;
        margin: 0 auto;
    }
    .years-section {
        --head-height: 10rem;
    }
    .hexing {
        transform: translate(10px, -20px);
    }
    .years__card__head {
        padding: 1rem 1rem;
    }
}

div.sortable-drag {
    /* transition: background-color var(--transition-smooth) ease; */
    background-color: theme("colors.blue.300");
}
.darkmode div.sortable-drag {
    transition: background-color var(--transition-smooth) ease;
    background-color: theme("colors.blue.500");
}
div.sortable-ghost {
    transition: background-color var(--transition-smooth) ease;
    background-color: theme("colors.blue.500");
}
.darkmode div.sortable-ghost {
    transition: background-color var(--transition-smooth) ease;
    background-color: theme("colors.blue.500");
}

.before-box::before {
    content: "";
    right: 50%;
    transform: translateX(50%) skew(10deg);
    width: 80%;
    position: absolute;
    bottom: 2px;
    height: 50%;
    /* transform: skewX(10deg); */
    /* transform-origin: canter center; */
    background-color: theme("colors.cyan.300");
    transition: all var(--transition-smooth) ease;
}
.darkmode .before-box::before {
    content: "";
    right: 50%;
    width: 80%;
    position: absolute;
    bottom: 2px;
    height: 40%;
    background-color: theme("colors.cyan.900");
    transition: all var(--transition-smooth) ease;
}

.prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
.swal-text {
    direction: rtl;
    text-align: center;
}

.absolute-celebration {
    position: relative;
    overflow: hidden;
}

.absolute-celebration::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(to bottom, #f7b733, #fc4a1a);
    opacity: 1; /* Adjust opacity as needed */
}

.absolute-celebration::after {
    content: "";
    opacity: 0.2;
    position: absolute;
    z-index: 1;
    top: 0px; /* Adjust initial position */
    left: 0;
    width: 100%;
    height: 400%; /* Adjust height */
    background-image: radial-gradient(circle, #ffffff 20%, transparent 20%),
        radial-gradient(circle, #ffffff 20%, transparent 20%);
    background-position: 0 0, 50px 50px; /* Adjust position */
    background-size: 100px 100px; /* Adjust size */
    animation: confetti-fall 5s ease-in-out infinite; /* Adjust animation duration as needed */
}

@keyframes confetti-fall {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-100px);
    }
    100% {
        transform: translateY(0);
    }
}
