.years__book {
    margin: 0.5rem;
    border-radius: var(--border-radius-small);
    overflow: hidden;
    opacity: 0;
    --years-delay: 0.5s;
    --years-duration: 1.4s;
    --years-cubic: ease-out;
    --years-starter-fill: var(--color-dark-2);
    --years-fillDuration: 1s;
}

.years__container.toAnimate .years__book:nth-child(3) {
    fill: var(--years-starter-fill);
    animation: yearsAnimation var(--years-duration) var(--years-cubic) forwards,
        fillYears1 var(--years-fillDuration) var(--years-cubic) forwards
            calc((var(--years-duration) / 6) + (0 * var(--years-delay)));
}
.years__container.toAnimate .years__book:nth-child(2) {
    fill: var(--years-starter-fill);
    animation: yearsAnimation var(--years-duration) var(--years-cubic) forwards
            calc(1 * var(--years-delay)),
        fillYears2 var(--years-fillDuration) var(--years-cubic) forwards
            calc((var(--years-duration) / 6) + (1 * var(--years-delay)));
}
.years__container.toAnimate .years__book:nth-child(1) {
    fill: var(--years-starter-fill);
    animation: yearsAnimation var(--years-duration) var(--years-cubic) forwards
            calc(2 * var(--years-delay)),
        fillYears3 var(--years-fillDuration) var(--years-cubic) forwards
            calc((var(--years-duration) / 6) + (2 * var(--years-delay)));
}

@keyframes yearsAnimation {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    60% {
        transform: translateX(0);
        opacity: 1;
    }
    80% {
        transform: scale(94%, 106%) translateX(3%);
    }
    100% {
        transform: scale(100%, 100%);
        opacity: 1;
    }
}

@keyframes fillYears1 {
    from {
        fill: var(--years-starter-fill);
    }
    to {
        /* fill: var(--color-purple-dark-1); */
        fill: theme("colors.purple.500");
    }
}
@keyframes fillYears2 {
    from {
        fill: var(--years-starter-fill);
    }
    to {
        /* fill: var(--color-rose-dark-1); */
        fill: theme("colors.rose.500");
    }
}
@keyframes fillYears3 {
    from {
        fill: var(--years-starter-fill);
    }
    to {
        /* fill: var(--color-teal-dark-1); */
        fill: theme("colors.teal.500");
    }
}

/* .cls-1 {
    fill: var(--color-teal-dark-1);
} */
.cls-2 {
    fill: var(--color-dark-0);
    opacity: 0.1;
}

.cls-3 {
    /* fill: var(--color-yellow-light-2); */
    fill: theme("colors.yellow.300");
}

.cls-4 {
    isolation: isolate;
}

.cls-5 {
    fill: var(--color-white);
}
